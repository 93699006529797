import { BaseSelect, SelectOption } from './BaseSelect';
import { useMemo } from 'react';

const ALL_OPTION: SelectOption = {
  value: 'ALL',
  label: 'All',
};

export type FilterSelectProps = {
  title: string;
  filterTitle?: string;
  options: SelectOption[];
  dataCy?: string;
  handleClearOptions: () => void;
  handleToggleOption: (optionId: string) => void;
} & (
  | {
      multiple: true;
      selectedValues: string[];
    }
  | {
      multiple?: false;
      selectedValues?: string;
    }
);

const getSelectedOptionLabels = (
  options: SelectOption[],
  optionIds?: string | string[],
) => {
  if (!optionIds?.length) return ALL_OPTION.label;

  const isArray = Array.isArray(optionIds);

  if (isArray && optionIds.length > 1) {
    return `${optionIds.length} Selected`;
  } else {
    const singleValue = isArray ? optionIds[0] : optionIds;
    return options.find((option) => option.value === singleValue)?.label ?? '';
  }
};

const handleToggleOptionWithAll = (
  optionId: string,
  handleClearOptions: () => void,
  handleToggleOption: (optionId: string) => void,
) => {
  if (optionId === ALL_OPTION.value) {
    handleClearOptions();
  } else {
    handleToggleOption(optionId);
  }
};

const getSelectedOptions = (
  selectedValues?: string | string[],
  multiple?: boolean,
) => {
  if (multiple) {
    return !selectedValues?.length ? [ALL_OPTION.value] : selectedValues;
  } else {
    return typeof selectedValues === 'undefined'
      ? ALL_OPTION.value
      : selectedValues;
  }
};

export const FilterSelect: React.FC<FilterSelectProps> = ({
  title,
  filterTitle = 'Filter By',
  options,
  selectedValues,
  multiple = false,
  dataCy = title.toLowerCase().replace(/ /g, '-'),
  handleClearOptions,
  handleToggleOption,
}) => {
  const allOptions = useMemo(
    () => [ALL_OPTION, ...options],
    [options]
  );

  return (
    <BaseSelect
      title={title}
      menuTitle={filterTitle}
      selectedLabel={getSelectedOptionLabels(options, selectedValues)}
      dataCy={dataCy}
      selectedValues={getSelectedOptions(selectedValues, multiple)}
      multiple={multiple}
      options={allOptions}
      onClick={(value) =>
        handleToggleOptionWithAll(value, handleClearOptions, handleToggleOption)
      }
    />
  );
};
