import { DownloadIcon } from '@chakra-ui/icons';
import {
  Button,
  Center,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { PaymentSummaryModal } from '../fleet-management/gig-jobs/PaymentSummaryModal';
import { GigJobsFiltersStore } from '../fleet-management/gig-jobs/useGigJobsFiltersStore';
import { useDownloadGigJobs } from './useDownloadGigJobs';

type DownloadType = 'INDIVIDUAL_JOBS' | 'PAYMENT_SUMMARY';

type DownloadGigJobsCsvProps = React.ComponentProps<typeof Button> & {
  filterStore: GigJobsFiltersStore;
};

export const DownloadGigJobsCsv: React.FC<DownloadGigJobsCsvProps> = ({
  filterStore,
  ...props
}) => {
  const disclosure = useDisclosure();

  const [downloadType, setDownloadType] =
    React.useState<DownloadType>('INDIVIDUAL_JOBS');

  const downloadGigJobsQuery = useDownloadGigJobs();

  const handleDownload = () => {
    switch (downloadType) {
      case 'INDIVIDUAL_JOBS':
        downloadGigJobsQuery.mutate(filterStore.filters);
        break;
      case 'PAYMENT_SUMMARY':
        disclosure.onOpen();
        break;
    }
  };

  const isLoading = downloadGigJobsQuery.isLoading;

  return (
    <>
      <Menu closeOnSelect={false}>
        <MenuButton as={Button} aria-label="Download Options" {...props}>
          <HStack>
            <DownloadIcon />
            <Text>Download CSV</Text>
          </HStack>
        </MenuButton>
        <MenuList minWidth="200px">
          <MenuOptionGroup
            title="Download Type"
            type="radio"
            value={downloadType}
            onChange={(value) => setDownloadType(value as DownloadType)}
          >
            <MenuItemOption value="INDIVIDUAL_JOBS">
              Individual Jobs
            </MenuItemOption>
            <MenuItemOption value="PAYMENT_SUMMARY">
              Payment Summary
            </MenuItemOption>
          </MenuOptionGroup>
          <MenuDivider />
          <Center>
            <Button
              width="90%"
              onClick={handleDownload}
              size="sm"
              isLoading={isLoading}
            >
              Download CSV
            </Button>
          </Center>
        </MenuList>
      </Menu>

      <PaymentSummaryModal
        isOpen={disclosure.isOpen}
        onClose={disclosure.onClose}
      />
    </>
  );
};
