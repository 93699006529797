import { useMutation } from '@tanstack/react-query';
import { downloadUrl } from '@tradeaze/frontend/utils';
import { fetchGigJobsCsv } from '@tradeaze-packages/api-client';
import { DownloadGigJobsQuery } from '@tradeaze-packages/schemas';
import toast from 'react-hot-toast';

export const useDownloadGigJobs = () => {
  let loadingToastId: string;

  return useMutation({
    mutationFn: async (filters: DownloadGigJobsQuery) => fetchGigJobsCsv(filters),
    onError() {
      toast.error('Error generating CSV');
    },
    onSuccess: async (data) => {
      toast.success('Generated CSV');
      downloadUrl(data.url, `Gig Jobs - ${new Date().toISOString()}.csv`);
    },
    onMutate: () => {
      loadingToastId = toast.loading('Generating CSV');
    },
    onSettled: () => {
      toast.dismiss(loadingToastId);
    },
  });
}; 