import { BaseSelect, SelectOption } from './BaseSelect';
import { useMemo } from 'react';

export type SortSelectProps = {
  title: string;
  filterTitle?: string;
  options: SelectOption[];
  selectedValue: string;
  dataCy?: string;
  onChange: (value: string) => void;
};

const getSelectedOptionLabels = (options: SelectOption[], optionId?: string) => {
  return options.find((option) => option.value === optionId)?.label ?? '';
};

export const SortSelect: React.FC<SortSelectProps> = ({
  title,
  filterTitle = 'Sort By',
  options,
  selectedValue,
  dataCy = title.toLowerCase().replace(/ /g, '-'),
  onChange,
}) => {
  const selectedLabel = useMemo(
    () => getSelectedOptionLabels(options, selectedValue),
    [options, selectedValue]
  );

  return (
    <BaseSelect
      title={title}
      menuTitle={filterTitle}
      selectedLabel={selectedLabel}
      dataCy={dataCy}
      selectedValues={selectedValue}
      options={options}
      onClick={(value) => onChange(value)}
      multiple={false}
    />
  );
};
