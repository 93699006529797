import { Box, HStack, Text } from '@chakra-ui/react';
import { SmallDatePicker } from '../../shared';
import { ClockInFiltersStore } from './useClockInFiltersStore';
import { TableToolbarContainer, FilterInput } from '../shared';

export const ClockInFilters: React.FC<ClockInFiltersStore> = ({
  filters,
  handleSelectFromDate,
  handleSelectToDate,
  handleChangeName,
  handleChangeRegNumber,
}) => {
  return (
    <TableToolbarContainer>
      <HStack spacing={'4'} alignItems={'end'}>
        <Box>
          <Text pl={1} fontSize={10}>
            From
          </Text>
          <SmallDatePicker
            date={new Date(filters.fromDate)}
            size="small"
            onChange={handleSelectFromDate}
            allowPastDates
            allowSunday
            maxDate={new Date(filters.toDate)}
          />
        </Box>
        <Box>
          <Text pl={1} fontSize={10}>
            To
          </Text>
          <SmallDatePicker
            date={new Date(filters.toDate)}
            size="small"
            onChange={handleSelectToDate}
            allowPastDates
            allowSunday
            minDate={new Date(filters.fromDate)}
            maxDate={new Date()}
          />
        </Box>
        <FilterInput
          label="Name"
          value={filters.riderName}
          onChange={handleChangeName}
        />
        <FilterInput
          label="Registration"
          value={filters.regNumber}
          onChange={handleChangeRegNumber}
        />
      </HStack>
    </TableToolbarContainer>
  );
};
