import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
} from '@chakra-ui/react';

export type BaseSelectProps = {
  title: string;
  menuTitle?: string;
  dataCy?: string;
  selectedLabel: string;
  multiple?: boolean;
  selectedValues?: string | string[];
  onClick: (value: string) => void;
  options: SelectOption[];
};

export type SelectOption = {
  label: string;
  value: string;
};

export const BaseSelect: React.FC<BaseSelectProps> = ({
  title,
  menuTitle,
  dataCy = title.toLowerCase().replace(/ /g, '-'),
  selectedLabel,
  multiple = false,
  selectedValues,
  onClick,
  options,
}) => {
  return (
    <Box data-cy={dataCy}>
      <Text
        data-cy={`${dataCy}-label`}
        pl={1}
        pb={'2px'}
        fontSize={14}
        fontWeight={500}
      >
        {title}
      </Text>
      <Menu closeOnSelect={false}>
        <Box>
          <MenuButton
            colorScheme="gray"
            size="sm"
            as={Button}
            data-cy={`${dataCy}-filter-btn`}
            rightIcon={<ChevronDownIcon />}
            minW="120px"
            textAlign="left"
          >
            {selectedLabel}
          </MenuButton>
        </Box>
        <MenuList maxWidth={'max-content'} zIndex="3">
          <MenuOptionGroup
            title={menuTitle}
            type={multiple ? 'checkbox' : 'radio'}
            value={selectedValues}
          >
            {options.map((option) => (
              <MenuItemOption
                key={option.value}
                data-cy={`${dataCy}-value`}
                value={option.value.toString()}
                onClick={() => onClick(option.value)}
              >
                {option.label}
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </Box>
  );
};
