import React from 'react';
import {
  Box,
  Button,
  Checkbox,
  HStack,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { RidersFiltersStore } from './useRiderTableFiltersStore';
import { TableToolbarContainer, FilterInput } from '../shared';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { RiderStatus } from '@tradeaze-packages/schemas';
import {
  filterByVerificationStatusLabel,
  RiderStatusKey,
} from './riderColumnDefs';

export const RidersFilters: React.FC<RidersFiltersStore> = ({
  filters,
  handleToggleIncludeDeleted,
  handleChangeRiderName,
  handleToggleIncludeUnassignable,
  handleChangeStatus,
}) => {
  const getRidersStatusLabel = (status: RiderStatusKey | undefined) => {
    if (status === undefined) {
      return filterByVerificationStatusLabel.ALL;
    }
    return filterByVerificationStatusLabel[status];
  };

  return (
    <TableToolbarContainer>
      <HStack spacing={'4'}>
        <Box mt={'-25px'} data-cy={'verification-dropdown'}>
          <Text
            pl={1}
            pb={'2px'}
            fontSize={14}
            fontWeight={500}
            data-cy={'verification-dropdown-title'}
          >
            Verification
          </Text>
          <Menu>
            <Box>
              <MenuButton
                colorScheme={'gray'}
                size={'sm'}
                as={Button}
                rightIcon={<ChevronDownIcon />}
                data-cy={'verification-dropdown-select-button'}
              >
                <span style={{ paddingRight: '50px' }}>
                  {getRidersStatusLabel(filters.status)}
                </span>
              </MenuButton>
            </Box>
            <MenuList maxWidth={'max-content'} zIndex="3">
              {Object.keys(filterByVerificationStatusLabel).map((key) => (
                <MenuItemOption
                  key={key}
                  value={key}
                  isChecked={
                    (filters.status === undefined && key === 'ALL') ||
                    filters.status === key
                  }
                  onClick={() =>
                    handleChangeStatus(
                      key === 'ALL' ? undefined : (key as RiderStatus),
                    )
                  }
                  data-cy={`verification-dropdown-option-${key}`}
                >
                  {filterByVerificationStatusLabel[key as RiderStatusKey]}
                </MenuItemOption>
              ))}
            </MenuList>
          </Menu>
        </Box>
        <FilterInput
          label="Name"
          value={filters.riderName}
          onChange={handleChangeRiderName}
        />
        <Checkbox
          isChecked={filters.includeDeleted}
          onChange={handleToggleIncludeDeleted}
          name="includeDeleted"
          data-cy="include-deleted"
          colorScheme="blue"
        >
          Include Deleted
        </Checkbox>
        <Checkbox
          isChecked={filters.includeUnassignable}
          onChange={handleToggleIncludeUnassignable}
          name="includeUnassignable"
          data-cy="include-unassignable"
          colorScheme="blue"
        >
          Include Unassignable
        </Checkbox>
      </HStack>
    </TableToolbarContainer>
  );
};
