import { Tag, Text, Wrap, WrapItem } from '@chakra-ui/react';
import { DeliveryVehicleId } from '@tradeaze-packages/schemas';
import { DeliveryVehicleIcon } from './DeliveryVehicleIcon';

type VehicleTypeTagProps = {
  vehicleIds: DeliveryVehicleId | DeliveryVehicleId[];
  size?: 'sm' | 'md' | 'lg';
  noWrap?: boolean;
};

export const DeliveryVehicleTypeTag = ({ 
  vehicleIds, 
  size = 'md',
  noWrap = false 
}: VehicleTypeTagProps) => {
  const vehicles = Array.isArray(vehicleIds) ? vehicleIds : [vehicleIds];

  const TagWrapper = noWrap ? 'div' : Wrap;
  const wrapperProps = noWrap ? { display: 'flex', gap: 2 } : { spacing: 2 };

  return (
    <TagWrapper {...wrapperProps}>
      {vehicles.map((vehicleId) => (
        <WrapItem key={vehicleId}>
          <Tag
            size={size}
            variant="subtle"
            colorScheme="gray"
            borderWidth={1}
            display="flex"
            alignItems="center"
            gap={2}
            backgroundColor="#EEEEEF"
            borderColor="#D5D5D5"
            textColor="gray.800"
            paddingX={2}
            paddingY={0}
          >
            <DeliveryVehicleIcon
              deliveryVehicle={vehicleId}
              fontSize="0.9em"
            />
            <Text fontSize="0.8em" fontWeight={600}>
              {vehicleId.replace(/_/g, ' ')}
            </Text>
          </Tag>
        </WrapItem>
      ))}
    </TagWrapper>
  );
};
