import { Box, HStack, Text } from '@chakra-ui/react';
import {
  DeliveryVehicleId,
  GigJobSortBy,
  GigJobStatus,
} from '@tradeaze-packages/schemas';
import React from 'react';
import { SmallDatePicker } from '../../shared';
import { FilterSelect } from '../../shared/select/FilterSelect';
import { SortSelect } from '../../shared/select/SortSelect';
import { FilterInput, TableToolbarContainer } from '../shared';
import {
  filterByJobStatusLabel,
  filterByPaymentStatusLabel,
  sortByToLabel,
  vehicleTypeLabelToVehicleType,
} from './filterOptions';
import { GigJobsFiltersStore } from './useGigJobsFiltersStore';

export const GigJobsFilters: React.FC<GigJobsFiltersStore> = ({
  filters,
  handleSelectFromDate,
  handleSelectToDate,
  handleChangeName,
  handleChangeDeliveryId,
  handleChangeGigJobId,
  handleChangeSortBy,
  handleChangePaymentStatus,
  handleToggleStatus,
  handleClearJobStatuses,
  handleToggleDeliveryVehicleId,
  handleClearDeliveryVehicleIds,
}) => {
  return (
    <TableToolbarContainer>
      <HStack spacing={'4'} alignItems={'end'}>
        <SortSelect
          title="Sort by"
          options={Object.entries(sortByToLabel).map(([key, value]) => ({
            label: value,
            value: key,
          }))}
          selectedValue={filters.sortBy ?? 'createdAt'}
          onChange={(value) => handleChangeSortBy(value as GigJobSortBy)}
        />
        <Box>
          <Text
            data-cy="from-date-label"
            pl={1}
            pb={'2px'}
            fontSize={14}
            fontWeight={500}
          >
            From
          </Text>
          <SmallDatePicker
            date={filters?.fromDate ? new Date(filters.fromDate) : undefined}
            size="small"
            id="from-datepick"
            data-cy="from-date"
            onChange={handleSelectFromDate}
            allowPastDates
            allowSunday
            maxDate={filters.toDate ? new Date(filters.toDate) : undefined}
          />
        </Box>
        <Box>
          <Text
            data-cy="to-date-label"
            pl={1}
            pb={'2px'}
            fontSize={14}
            fontWeight={500}
          >
            To
          </Text>
          <SmallDatePicker
            date={filters?.toDate ? new Date(filters.toDate) : undefined}
            size="small"
            id="to-datepick"
            data-cy="to-date"
            onChange={handleSelectToDate}
            allowPastDates
            allowSunday
            minDate={filters?.fromDate ? new Date(filters.fromDate) : undefined}
            maxDate={new Date()}
          />
        </Box>
        <FilterSelect
          title="Job Status"
          multiple
          options={Object.entries(filterByJobStatusLabel)
            .filter((x) => x[0] !== 'ALL')
            .map(([key, value]) => ({
              label: value,
              value: key,
            }))}
          selectedValues={filters.status ?? []}
          handleClearOptions={handleClearJobStatuses}
          handleToggleOption={(optionId) =>
            handleToggleStatus(optionId as GigJobStatus)
          }
        />
        <FilterInput
          label="Driver"
          value={filters.driverName}
          onChange={handleChangeName}
          placeholder="Driver Name"
        />
        <FilterInput
          label="Delivery ID"
          value={filters.deliveryId}
          onChange={handleChangeDeliveryId}
        />
        <FilterInput
          label="Job ID"
          value={filters.gigJobId}
          onChange={handleChangeGigJobId}
        />
        <FilterSelect
          title="Booked Vehicle Type"
          multiple
          options={Object.entries(vehicleTypeLabelToVehicleType).map(
            ([key, value]) => ({
              label: value,
              value: key,
            }),
          )}
          selectedValues={filters.deliveryVehicleIds ?? []}
          handleClearOptions={handleClearDeliveryVehicleIds}
          handleToggleOption={(optionId) =>
            handleToggleDeliveryVehicleId(optionId as DeliveryVehicleId)
          }
        />
        <FilterSelect
          title="Payment Status"
          options={Object.entries(filterByPaymentStatusLabel)
            .filter((x) => x[0] !== 'all')
            .map(([key, value]) => ({
              label: value,
              value: key,
            }))}
          selectedValues={
            filters.paidStatus === true
              ? 'paid'
              : filters.paidStatus === false
              ? 'unpaid'
              : undefined
          }
          handleClearOptions={() => handleChangePaymentStatus(undefined)}
          handleToggleOption={(optionId) =>
            handleChangePaymentStatus(optionId === 'paid')
          }
        />
      </HStack>
    </TableToolbarContainer>
  );
};
