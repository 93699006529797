import { Button, HStack } from '@chakra-ui/react';
import { DownloadGigJobsCsv } from '../../csv/DownloadGigJobsCsv';
import { TableToolbarContainer } from '../shared';
import { GigJobsFiltersStore } from './useGigJobsFiltersStore';

export const GigJobTableActions: React.FC<{
  handleMarkAsPaid: () => void;
  showMarkAsPaid: boolean;
  filterStore: GigJobsFiltersStore;
}> = ({ handleMarkAsPaid, showMarkAsPaid, filterStore }) => {
  return (
    <TableToolbarContainer>
      <HStack spacing={2}>
        {showMarkAsPaid && (
          <Button
            size={'sm'}
            variant="outline"
            onClick={handleMarkAsPaid}
            data-cy="mark-as-paid-button"
          >
            Mark as paid
          </Button>
        )}
        <DownloadGigJobsCsv
          filterStore={filterStore}
          size={'sm'}
          variant="outline"
          data-cy="gig-jobs-download-button"
        />
      </HStack>
    </TableToolbarContainer>
  );
};
