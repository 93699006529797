import {
  DeliveryVehicleId,
  GigJobSortBy,
  GigJobStatus,
} from '@tradeaze-packages/schemas';

export const sortByToLabel: {
  [key in GigJobSortBy]: string;
} = {
  createdAt: 'Created At',
  paidAt: 'Paid At',
  completedAt: 'Completed At',
};

export const filterByPaymentStatusLabel = {
  paid: 'Paid',
  unpaid: 'Unpaid',
};

export const filterByJobStatusLabel: {
  [key in GigJobStatus]: string;
} = {
  PENDING: 'Pending',
  ACCEPTED: 'Accepted',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
  CANCELLED: 'Cancelled',
};

export const vehicleTypeLabelToVehicleType: {
  [key in DeliveryVehicleId]: string;
} = {
  BIKE: 'Bike',
  BICYCLE: 'Bicycle',
  MOTORBIKE: 'Motorbike',
  CAR: 'Car',
  VAN_MEDIUM: 'Van Medium',
  VAN_LARGE: 'Van Large',
  VAN_XLARGE: 'Van XLarge',
  LUTON: 'Luton',
  FLATBED: 'Flatbed',
  HIAB: 'Hiab',
  LORRY_MEDIUM: 'Lorry Medium',
};