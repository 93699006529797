import { Box, FormLabel } from '@chakra-ui/react';
import { DeliveryVehicleId } from '@tradeaze-packages/schemas';
import { DeliveryVehicleTypeTag } from '../../delivery';

type GigJobVehiclesProps = {
  gigJobBoard?: Array<{
    deliveryVehicleId: DeliveryVehicleId;
  }> | null;
};

export const GigJobVehicles = ({ gigJobBoard }: GigJobVehiclesProps) => {
  if (!gigJobBoard || gigJobBoard.length === 0) {
    return null;
  }

  const uniqueVehicles = Array.from(
    new Set(gigJobBoard.map((board) => board.deliveryVehicleId)),
  );

  return (
    <Box>
      <FormLabel mb={4}>Job Board Vehicles</FormLabel>
      <DeliveryVehicleTypeTag vehicleIds={uniqueVehicles} />
    </Box>
  );
};
