import { Box } from '@chakra-ui/react';
import React from 'react';

export const TableToolbarContainer: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <Box py={6} overflowX={'auto'}>
      {children}
    </Box>
  );
};
