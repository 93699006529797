import { Flex, Tag, Text } from '@chakra-ui/react';
import { DeliveryVehicleId, HydratedGigJob } from '@tradeaze-packages/schemas';
import { useGetGigJobs, useMarkGigJobAsPaid } from '@tradeaze/frontend/hooks';
import { formatDate, formatJourneyPostcodes } from '@tradeaze/shared/utils';
import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import { CustomCellRendererProps } from 'ag-grid-react';
import { useCallback, useState } from 'react';
import { CardPaymentStatus } from '../../jobs';
import { useAgGridApi, useAgGridLoading } from '../shared';
import { JobStatusLabel } from './components';
import { useGigJobsFiltersStore } from './useGigJobsFiltersStore';
import { DeliveryVehicleTypeTag } from '../../delivery';

const columnDefs: ColDef[] = [
  {
    headerName: '',
    field: 'selection',
    checkboxSelection: true,
    headerCheckboxSelection: true,
    width: 50,
    pinned: 'left',
  },
  {
    headerName: 'Job ID',
    field: 'gigJobId',
    initialWidth: 100,
  },
  {
    headerName: 'Job Status',
    field: 'status',
    initialWidth: 150,
    cellRenderer: JobStatusLabel,
    cellStyle: {
      display: 'flex',
      alignItems: 'left',
    },
  },
  {
    headerName: 'Driver Name',
    field: 'rider',
    valueFormatter: ({ value }: ValueFormatterParams) =>
      value ? `${value.firstName} ${value.lastName}` : '',
    initialWidth: 150,
  },
  {
    headerName: 'Deliveries',
    field: 'deliveries',
    valueFormatter: ({ value }: ValueFormatterParams) => {
      const deliveres = value;
      
      return formatJourneyPostcodes(deliveres);
    },
    cellRenderer: ({ value }: CustomCellRendererProps) => {
      const deliveries = value;

    const uniquePostcodes = formatJourneyPostcodes(deliveries);

    const cancelledCount = deliveries.filter(
      (delivery: { status: string }) =>
        delivery.status === 'CANCELLED' || delivery.status === 'REJECTED',
    ).length;

    return (
      <Flex alignItems="center" gap={2}>
        <Text>{uniquePostcodes}</Text>
        {cancelledCount > 0 && (
          <Tag colorScheme="red" size="sm">
            {cancelledCount} cancelled
          </Tag>
        )}
      </Flex>
    );
  },
  initialWidth: 240,
},
{
  headerName: 'Payment Amount',
  field: 'paymentAmount',
  initialWidth: 150,
},
{
  headerName: 'Currency',
  field: 'paymentCurrency',
  initialWidth: 150,
},
{
  headerName: 'Paid at',
  field: 'paidAt',
  cellRenderer: ({ value }: ValueFormatterParams) =>
    value ? (
      formatDate(value)
    ) : (
      <CardPaymentStatus paymentStatus="UNPAID" />
    ),
  cellStyle: {
    display: 'flex',
    alignItems: 'left',
  },
  initialWidth: 150,
},
{
  headerName: 'Driver Email',
  field: 'rider.contactEmail',
  initialWidth: 150,
},
{
  headerName: 'Created at',
  field: 'createdAt',
  valueFormatter: ({ value }: ValueFormatterParams) =>
    value ? formatDate(value) : '',
  initialWidth: 150,
},
{
  headerName: 'Completed at',
  field: 'completedAt',
  valueFormatter: ({ value }: ValueFormatterParams) =>
    value ? formatDate(value) : '',
  initialWidth: 150,
},
{
  headerName: 'Vehicle Type (Booked)',
  field: 'deliveries',
  cellRenderer: ({ value }: { value: Array<{ deliveryVehicleId: DeliveryVehicleId }> }) => {
    const vehicleIds = value.map((delivery) => delivery.deliveryVehicleId);
    return <DeliveryVehicleTypeTag vehicleIds={vehicleIds} size='sm' noWrap />;
  },
  cellStyle: {
    display: 'flex',
    alignItems: 'center'
  },
  initialWidth: 150,
},
]

export const useGigJobTable = () => {
  const [selectedGigJobIds, setSelectedGigJobIds] = useState<string[]>([]);
  const filterStore = useGigJobsFiltersStore();

  const gigJobsQuery = useGetGigJobs({
    ...filterStore.filters,
    startIndex: 0,
    limit: 500,
  });

  const markGigJobsAsPaidMutation = useMarkGigJobAsPaid();

  const { onGridReady, gridApi } = useAgGridApi();

  useAgGridLoading(gridApi, gigJobsQuery.isLoading);

  const allJobs = gigJobsQuery.data?.gigJobs || [];

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridApi?.getSelectedRows() || [];
    setSelectedGigJobIds(
      selectedRows.map((row: HydratedGigJob) => row.gigJobId),
    );
  }, [gridApi]);

  const handleMarkSelectedAsPaid = () => {
    markGigJobsAsPaidMutation.mutate({ gigJobIds: selectedGigJobIds });
  };

  const showMarkAsPaid = selectedGigJobIds.length > 0;

  return {
    allJobs,
    onGridReady,
    columnDefs,
    filterStore,
    selectedGigJobIds,
    onSelectionChanged,
    handleMarkSelectedAsPaid,
    showMarkAsPaid,
  };
};
